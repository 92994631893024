.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  z-index: 10;
  width: 100%;
  background-color: #fff;

  @include mq($until: md) {
    &:after {
      display: none;
    }
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 25px;
      left: 50%;
      @include global-gradient($color-main, $color-extra);
      transform: translateX(-50%);
    }
  }
  &--fixed {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    transition: background-color 0.3s ease-in-out;
    &.sticky {
    }
  }
  &__logo {
    position: absolute;
    top: -4px;
    z-index: 3;
    width: 221px;
    @include mq($until: lg) {
      width: 200px;
    }
    @include mq($until: md) {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      top: auto;
      transform: none;
    }
    @include mq($until: xs) {
      width: 150px;
    }
    img {
      width: 100%;
    }
  }
  &__nav {
    width: 100%;
    z-index: 1;
  }
  &__nav-wrapper {
    display: grid;
    grid-template-columns: 1fr 296px;
    position: relative;
    padding-top: 7px;
    padding-bottom: 7px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    @include mq($until: xl) {
      grid-template-columns: 1fr auto;
    }
    @include mq($until: md) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-direction: column;
      overflow-y: scroll;
      padding: 0;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100vw;
      height: 100%;
      @include global-gradient($color-main, $color-extra);
      @include mq($until: md) {
        display: none;
      }
    }
  }

  &__top-bar {
    display: grid;
    // height: 98px;
    grid-template-columns: 1fr 22% 320px;
    @include mq($until: md) {
      grid-template-columns: 1fr;
    }
  }

  &__contact-wrapper {
    display: flex;
    margin-left: auto;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-left: 200px;
    padding-right: 50px;
    min-width: 416px;
    @include mq($until: xl) {
      padding-right: 30px;
    }
    @include mq($until: lg) {
      padding-right: 20px;
    }
    @include mq($until: md) {
      display: none;
      padding-right: 20px;
    }
  }

  &__tel-label {
    font-weight: $fw-bold;
    font-style: italic;
    font-size: rem(16px);
    color: #434343;
  }

  &__tel-contact {
    color: $color-main;
    margin-right: 18px;
    font-weight: $fw-bold;
    font-size: rem(20px);
    svg {
      width: 25px;
      fill: $color-main;
    }
    &:hover {
      color: $color-font-dark;
      svg {
        fill: $color-font-dark;
      }
    }
  }

  &__menuList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 190px;
    padding-right: 50px;
    margin: 0;
    @include mq($until: lg) {
      // padding-right: 30px;
      padding-left: 130px;
    }
  }
  &__menuItem {
    margin: 0;
    padding: 0;
    color: $color-font-light;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
      display: none;
    }
  }
  &__menuLink {
    cursor: pointer;
    color: $color-font-light;
    font-weight: $fw-bold;
    transition: color 0.2s;
    font-size: rem(20px);
    @include mq($until: xl) {
      font-size: rem(18px);
    }
    @include mq($until: lg) {
      font-size: rem(16px);
    }
    @include mq($until: md) {
      font-size: rem(20px);
    }
    &:active,
    &--active,
    &--open,
    &:hover {
      color: $color-font-dark;
    }
  }
  &__aside-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq($until: md) {
      width: 100%;
    }
    &:before {
      margin: 0 20px;
      content: "";
      height: 40px;
      width: 1px;
      background-color: $color-font-light;
      order: 2;
      opacity: 0.6;
      @include mq($until: md) {
        display: none;
      }
    }
  }
  &__social-media {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    order: 1;
    margin: 0;
  }
  &__social-media-item {
    margin: 0 10px;
    padding: 0;
    @include mq($until: xl) {
      margin: 0 5px;
    }
    &:before {
      display: none;
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  &__social-media-link {
    svg {
      fill: $color-font-light;
      transition: 0.3s;
      // opacity: .6;
      width: 35px;
      @include mq($until: xl) {
        width: 30px;
      }
    }
    &:hover {
      svg {
        // opacity: 1;
        fill: $color-font-dark;
      }
    }
  }
  &__contact {
    order: 3;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
  }
  &__contact-item {
    margin: 0 10px;
    padding: 0;
    &:before {
      display: none;
    }
    &--phone {
      margin-left: 0;
      display: none;
      @include mq($until: md) {
        display: block;
      }
    }
    &--email {
      margin: 0;
      @include mq($until: md) {
        margin: 0 0 0 10px;
      }
    }
  }
  &__contact-link {
    svg {
      fill: $color-font-light;
      transition: 0.3s;
      width: 35px;
      @include mq($until: xl) {
        width: 30px;
      }
    }
    &:hover svg {
      fill: $color-font-dark;
    }
  }
  &__player-bar {
    width: 100%;
    padding: 20px 0;
    position: relative;
    background-color: #000;
    z-index: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include mq($until: lg) {
      justify-content: space-between;
    }
    @include mq($until: md) {
      margin-top: 11px;
      padding: 10px 0;
    }
    @include mq($until: xs) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      height: calc(100% + 10px);
      width: 100vw;
      background-color: #000;
      z-index: -1;
      display: block;
    }
  }
  &__player {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 120px;
    @include mq($until: lg) {
      margin-right: 20px;
    }
  }
  &__player-label {
    color: $color-font-light;
    font-size: rem(40px);
    font-weight: $fw-bold;
    white-space: nowrap;
    margin-right: 30px;
    @include mq($until: lg) {
      font-size: rem(30px);
    }
    @include mq($until: md) {
      margin-right: 10px;
    }
  }
  &__stream-wrapper {
    display: none;
  }
  &__play-button {
    border: none;
    background-color: transparent;
    color: $color-font-light;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    svg {
      width: 100px;
      height: 91px;
      transition: transform 0.3s;
      &:hover {
        transform: scale(1.05);
      }
      @include mq($until: lg) {
        width: 80px;
        height: 71px;
      }
      @include mq($until: md) {
        width: 60px;
        height: 51px;
      }
      @include mq($until: xs) {
        width: 50px;
        height: 41px;
      }
    }
  }
  &__current-play-wrapper {
    display: flex;
    justify-content: center;
    align-items: stretch;
  }
  &__player-decorator {
    margin-right: 15px;
    @include mq($until: sm) {
      margin-right: 8px;
    }
    svg {
      margin-top: 8px;
      @include mq($until: xl) {
        margin-top: 4px;
        width: 60px;
      }
      @include mq($until: lg) {
        width: 53px;
        margin-top: 0;
      }
      @include mq($until: md) {
        width: 48px;
      }
      @include mq($until: sm) {
        width: 42px;
      }
    }
  }
  &__current-play-inner-wrapper {
    display: flex;
    // flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  &__current-play-label {
    font-size: rem(22px);
    width: 100%;
    font-weight: $fw-bold;
    color: $color-font-light;
    white-space: nowrap;
    @include mq($until: xl) {
      font-size: rem(20px);
    }
    @include mq($until: lg) {
      font-size: rem(18px);
    }
    @include mq($until: md) {
      font-size: rem(14px);
      margin-top: 4px;
    }
    @include mq($until: sm) {
      margin-top: 7px;
    }
    @include mq($until: xs) {
      margin-right: 10px;
      margin-top: 8px;
    }
  }
  &__ticker {
    width: 100%;
    font-size: rem(28px);
    line-height: rem(28px);
    margin-top: 5px;
    font-weight: $fw-bold;
    color: $color-font-light;
    @include mq($until: xl) {
      font-size: rem(24px);
      line-height: rem(24px);
      margin-top: 8px;
    }
    @include mq($until: lg) {
      font-size: rem(22px);
      line-height: rem(2px);
      margin-top: 0;
    }
    @include mq($until: md) {
      font-size: rem(18px);
      line-height: rem(18px);
      margin-top: 2px;
    }
    @include mq($until: sm) {
      margin-top: 0px;
    }
  }
}
