.podcast {
  position: relative;
  &__header {
    display: grid;
    grid-template-columns: 220px 1fr;
    grid-gap: 50px;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid $border-color;
    z-index: 1;
    @include mq($until: sm) {
      grid-template-columns: 170px 1fr;
    }
    @include mq($until: xs) {
      grid-template-columns: 1fr;
    }
  }
  &__thumb {
    width: 220px;
    height: 220px;
    background-size: auto;
    background-size: cover;
    background-position: center;
    display: block;
    border-radius: 5px;
    @include mq($until: sm) {
      width: 170px;
      height: 170px;
    }
    @include mq($until: xs) {
      width: 100%;
      height: 90vw;
    }
  }
  &__description {
  }
  &__items {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin: 0;
  }
  &__item {
    padding: 20px 20px 20px 40px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 25px;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.07);
    background: #fff;
    background: linear-gradient(
      0deg,
      rgba($color: #fff, $alpha: 1) 0%,
      rgba($color: #fff, $alpha: 1) 100%
    );
    transition: background-color 0.3s;
    overflow: hidden;
    border-radius: 5px;
    margin-top: 0;
    &:before {
      display: none;
    }
  }
  &__play {
    margin-right: 30px;
    flex-shrink: 0;
    background-color: transparent;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.03);
    border: none;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    @include global-gradient($color-main, $color-extra);
    display: flex;
    transition: 0.3s;
    justify-content: center;
    align-items: center;
    &:hover {
      transform: scale(1.08);
      box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.12);
    }
    svg {
      width: 15px;
      &#icon-play {
        margin-left: 3px;
      }
      &#icon-pause {
        margin-left: 0px;
      }
      &:hover {
        fill: #fff;
      }
    }
  }
  &__item-title {
    font-size: rem(19px);
    font-weight: $fw-bold;
    transition: color 0.2s;
    cursor: pointer;
    &:hover {
      color: $color-main;
    }
  }
  &__details-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #535353;
    font-size: rem(14px);
  }
  &__recording-time {
    font-size: inherit;
    color: inherit;
  }
  &__duration {
    font-size: inherit;
    color: inherit;
  }
  &__duration-label {
    font-size: inherit;
    color: inherit;
  }
  &__date {
    font-size: inherit;
    color: inherit;
  }
  &__separator {
    margin: 0 8px;
    color: inherit;
    font-size: rem(16px);
  }
  &__read-more {
    border: none;
    background-color: transparent;
    font-size: rem(14px);
    position: relative;
    padding-right: 20px;
    padding-bottom: 5px;
    padding-left: 0;
    cursor: pointer;
    transition: color 0.2s;
    &:hover,
    &:hover:before {
      color: $color-main;
    }
    &:after {
      content: "^";
      position: absolute;
      right: 0;
      transition: color 0.2s;
      top: 0;
      margin-right: 5px;
      transform: rotate(180deg);
      font-size: inherit;
      color: inherit;
      font-size: rem(12px);
    }
    &.active:after {
      transform: rotate(0deg);
    }
  }

  &__item-description {
    padding: 0;
    max-height: 0;
    overflow: hidden;
    font-size: rem(12px);
    transition: max-height 0.2s ease-out;
  }
  &__not-found {
    display: block;
    text-align: center;
    color: $border-color;
    font-size: rem(22px);
    &:before {
      display: none;
    }
  }
  &__loader-wrapper {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    width: calc(100% + 30px);
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 1;
    &:before {
      content: "";
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-color: #fff;
      position: absolute;
    }
  }
  &__loader {
  }
}
