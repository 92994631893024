footer {
  width: 100%;
  @include mq($until: md) {
    z-index: 2;
  }
}

.footer-small {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
  @include mq($until: xs) {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &__realization {
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq($until: xs) {
      margin-top: 20px;
    }
    span {
      transition: color 0.3s ease-in-out;
    }
    &:hover span {
      color: $color-main;
    }
    img {
      margin-left: 15px;
    }
  }
}

.footer-company {
  display: grid;
  grid-template-columns: 221px 1fr;
  align-items: flex-start;
  position: relative;
  @include mq($until: md) {
    grid-template-columns: 1fr;
  }
  &:before {
    content: "";
    height: 100%;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 50%;
    background-color: #fff;
    transform: translateX(-50%);
    -webkit-box-shadow: 2px -7px 9px -4px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 2px -7px 9px -4px rgba(0, 0, 0, 0.14);
    box-shadow: 2px -7px 9px -4px rgba(0, 0, 0, 0.14);
    z-index: 0;
  }
  @include mq($until: sm) {
    grid-template-columns: 1fr;
    justify-content: center;
  }
  &__container {
    display: grid;
    grid-template-columns: 1fr auto;
    @include mq($until: sm) {
      grid-template-columns: 1fr;
    }
  }
  &__logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 1;
    @include mq($until: md) {
      margin: 0 auto;
    }
  }
  &__menu-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 20px;
    @include mq($until: sm) {
      margin-bottom: 0;
    }
    &--nav{
      @include mq($until: xl) {
        margin: 30px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px 50px;
      }
      @include mq($until: lg) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include mq($until: md) {
        grid-gap: 40px;
        display: flex;
        justify-content: space-between;
        
      }
      // @include mq($until: sm) {
      //   grid-template-columns: repeat(3, 1fr);
      // }
      @include mq($until: xs) {
        flex-direction: column;
        justify-content: flex-start;
        // display: grid;

        // grid-template-columns: 1fr;
      }
    }
  }
  &__item {
    margin: 20px;
    padding: 0;
    font-size: rem(20px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    &--social-media {
      margin: 0 5px;
      &:hover svg {
        fill: $color-main;
      }
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    &:before {
      display: none;
    }
    @include mq($until: xl) {
      margin: 0;
    }
  }
  &__link {
    color: $color-font-dark;
    font-weight: $fw-bold;
    font-size: inherit;
    margin-bottom: 10px;
    &:hover {
      color: $color-main;
    }
    &--header{
      &:hover{
        color: $color-font-dark;
      }
    }
    &.media ~ .footer-company__submenu{
      flex-direction: row;
      flex-wrap: wrap;
      &>li{
        margin: 5px;
      }

    }
  }
  &__submenu{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    & .footer-company__item{
      font-size: rem(14px);
      margin: 0 0 10px 0;
    }
    & .footer-company__link{
      margin-bottom: 0;
    }
  }
}

.logo-absolute {
  .footer-company {
    margin-top: 0;
    &__logo {
      width: 221px;
      height: 120px;
      position: relative;
      img {
        max-width: none;
        width: 100%;
        position: absolute;
        bottom: 8px;
        left: 0;
      }
    }
  }
}
