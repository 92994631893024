.TickerNews {
    width: 100%;
    height: 50px;
    line-height: 50px;
  }
  
.ti_wrapper {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 50px;
}
  
.ti_slide {
    width: 30000px;
    position: relative;
    left: 0;
    top: 0;
}
  
.ti_content {
    width: 8000px;
    position: relative;
    float: left;
}
  
.ti_news { float: left; }