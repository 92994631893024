.player {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 720px;
  margin: 0 auto;
  padding: 0 10px;
  &__logo {
    margin: 10px auto 0 auto;
  }
  &__bar {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include global-gradient($color-main, $color-extra);
    padding: 5px 0;
    border-radius: 5px 5px 0 0;
    height: 50px;
  }
  &__play-btn {
    margin-right: 10px;
    background-color: transparent;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 20px;
    @include mq($until: xs) {
      margin-right: 5px;
    }
  }
  &__counter {
    margin: 0 10px;
    width: 100px;
    text-align: center;
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 20px 5px 20px 5px 20px;
    @include mq($until: xs) {
      margin: 0 5px;
    }
    span {
      text-align: center;
      font-weight: $fw-semi-bold;
      font-size: 15px;
      @include mq($until: xs) {
        font-size: 14px;
      }
    }
  }
  &__voluem-wrapper {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__volume-mute {
    background-color: transparent;
    padding: 0;
    margin: 0 0 0 10px;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover img {
      transform: scale(1.1);
    }
    img {
      transition: transform 0.2s;
    }
  }
  &__volume-icon {
    cursor: pointer;
  }
  &__volume {
    overflow: hidden;
    height: 12px;
    width: 100px;
    outline: none;
    padding: 0;
    background-color: $color-main;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    background-image: -webkit-gradient(
      linear,
      50% 0%,
      50% 100%,
      color-stop(0%, #fff),
      color-stop(100%, #fff)
    );
    background-size: 50% 100%;
    background-repeat: no-repeat;
    border-radius: 10px;
    cursor: pointer;
    -webkit-appearance: none;
    @include mq($until: sm) {
      width: 80px;
    }
    @include mq($until: xs) {
      width: 70px;
    }

    // chrome
    &::-webkit-slider-thumb {
      height: 12px;
      width: 0px;
      border: 0;
      background: #fff;
      -webkit-appearance: none;
    }

    &::-webkit-slider-runnable-track {
      box-shadow: none;
      border: none;
      background: transparent;
      -webkit-appearance: none;
    }

    // mozilla
    &::-moz-range-thumb {
      opacity: 0;
      outline: none;
      cursor: pointer;
    }

    &::-moz-range-progress {
      background-color: #fff;
      height: 100%;
      border: none;
    }
    &::-moz-range-track {
      background-color: $color-main;
      border-radius: 30px;
      border: none;
      height: 100%;
      box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    }

    // //IE
    &::-ms-fill-lower {
      background-color: #fff;
      height: 100%;
      border: none;
    }

    &::-ms-fill-upper {
      background-color: $color-main;
      border-radius: 30px;
      border: none;
      height: 100%;
      box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    }
  }
  &__button {
    fill: #fff;
    cursor: pointer;
    transition: fill 0.3s;
    &:hover {
      fill: #000;
    }
    &--hidden {
      display: none;
    }
  }
  &__current-play-wrapper {
    width: 100%;
    padding: 30px 20px 30px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    background-color: #000;
    border-radius: 0 0 5px 5px;
    margin-bottom: 20px;
    @include mq($until: sm) {
      margin-bottom: 15px;
      padding: 25px 15px 25px 15px;
    }
    @include mq($until: xs) {
      margin-bottom: 10px;
      padding: 20px 10px 20px 10px;
    }
  }
  &__player-decorator {
    margin-right: 15px;
    @include mq($until: sm) {
      margin-right: 8px;
    }
    svg {
      margin-top: 8px;
      width: 49px;

      @include mq($until: xs) {
        width: 46px;
      }
    }
  }
  &__current-play-inner-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  &__current-play-label {
    width: 100%;
    font-weight: $fw-bold;
    color: $color-font-light;
    white-space: nowrap;
    font-size: 15px;
    margin-right: 10px;
    margin-top: 11px;
    @include mq($until: xs) {
      font-size: 13px;
      margin-top: 14px;
    }
  }
  &__ticker {
    width: 100%;
    font-size: 18px;
    line-height: 18px;
    margin-top: 1px;
    font-weight: $fw-bold;
    color: $color-font-light;
    @include mq($until: xs) {
      font-size: 15px;
      line-height: 15px;
      margin-top: 0;
    }
  }
  &__links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin-bottom: 20px;
    @include mq($until: sm) {
      margin-bottom: 15px;
      grid-gap: 15px;
    }
    @include mq($until: xs) {
      margin-bottom: 10px;
      grid-gap: 10px;
    }
  }
  &__link {
    &:hover {
      .player__image {
        transform: scale(1.05);
      }
    }
  }
  &__image {
    border: 1px solid transparent;
    display: block;
    position: relative;
    border-radius: 5px;
    transition: transform 0.2s;
  }
}

.floating-player {
  position: fixed;
  transform: translateY(100%);
  width: 100vw;
  bottom: 0;
  left: 0;
  @include global-gradient($color-main, $color-extra);
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.12);
  z-index: 2;
  transition: transform 0.4s;
  &.active {
    transform: translateY(0);
  }
  &__container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  &__track-thumb-wrapper {
    max-width: 150px;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 20px;
    @include mq($until: sm) {
      max-width: 120px;
    }
    @include mq($until: xs) {
      max-width: 20vw;
    }
  }
  &__track-thumb {
    display: block;
  }
  &__inner-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }
  &__track-info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  &__track-title {
    font-size: rem(19px);
    font-weight: $fw-bold;
  }
  &__track-author {
    font-size: rem(16px);
  }
  &__bars-wrapper {
    display: grid;
    grid-template-columns: 80% 20%;
    grid-gap: 20px;
    width: 40%;
    min-width: 600px;
    @include mq($until: md) {
      grid-template-columns: 70% 30%;
      min-width: 400px;
    }
    @include mq($until: sm) {
      min-width: 100%;
      padding-right: $padding-sm;
    }
    @include mq($until: xs) {
      grid-gap: 10px;
      grid-template-columns: 60% 40%;
      padding-right: $padding-xs;
    }
  }
  &__time-progress-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
  &__time-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__time {
    font-size: rem(14px);
    color: $color-font-dark;

    span {
      font-size: inherit;
      font-style: inherit;
    }
  }
  &__progress-bar {
    position: relative;
    width: 100%;
    height: 8px;
    margin-top: 6px;
    cursor: pointer;
    &:hover {
      .floating-player__track-slider::-webkit-slider-thumb {
        display: block;
      }
      .floating-player__track-slider::-moz-range-thumb {
        visibility: visible;
      }
    }
    *::-ms-backdrop {
      color: #fff;
      border: none;
      background-color: transparent;
      @media all and (-ms-high-contrast: none) {
        color: #78909c;
        border: none;
        padding: 0px;
        height: 15px;
        width: 15px;
        border-radius: 10px;
        cursor: pointer;
        margin-top: -8px;
        border-width: 15px 0;
        border-color: transparent;
        background: transparent;
      }
    }
    progress::-ms-fill {
      border: none;
    }
  }
  &__track-slider {
    -webkit-appearance: none;
    width: 100%;
    // margin: 7.5px 0;
    position: absolute;
    z-index: 30;
    height: 100%;
    cursor: pointer;
    background-color: inherit;

    &::-webkit-slider-runnable-track {
      box-shadow: none;
      border: none;
      background: transparent;
      -webkit-appearance: none;
    }

    &::-webkit-slider-thumb {
      box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
      border: 1px solid #fff;
      height: 15px;
      width: 15px;
      border-radius: 16px;
      background: #fff;
      cursor: pointer;
      -webkit-appearance: none;
      // margin-top: -7.5px;
    }
    &::-moz-range-track {
      width: 100%;
      height: 100%;
      cursor: pointer;
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
      background: transparent;
      border-radius: 0px;
      border: 0px solid #010101;
    }
    &::-moz-range-thumb {
      box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
      border: 1px solid #fff;
      height: 15px;
      width: 15px;
      margin-top: 200px;
      border-radius: 16px;
      background: #fff;
      cursor: pointer;
      display: block;
    }
    &::-ms-track {
      width: 100%;
      height: 0px;
      cursor: pointer;
      background: transparent;
      border-color: transparent;
      color: transparent;
    }
    &::-ms-fill-lower {
      background: #003d57;
      border: 0px solid #010101;
      border-radius: 0px;
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
    }
    &::-ms-fill-upper {
      background: #fff;
      border: 0px solid #010101;
      border-radius: 0px;
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px rgba(13, 13, 13, 0);
    }
    &::-ms-thumb {
      box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
      border: 1px solid #fff;
      height: 15px;
      width: 15px;
      border-radius: 16px;
      background: #fff;
      cursor: pointer;
      height: 0px;
      display: none;
    }
    @media all and (-ms-high-contrast: none) {
      padding: 0px;
      &::-ms-thumb {
        height: 15px;
        width: 15px;
        border-radius: 10px;
        cursor: pointer;
        margin-top: -8px;
      }
      &::-ms-track {
        border-width: 15px 0;
        border-color: transparent;
      }
      &::-ms-fill-lower,
      &::-ms-fill-upper {
        background: transparent;
        border-radius: 10px;
      }
    }
    @supports (-ms-ime-align: auto) {
      &::-ms-thumb {
        height: 15px;
        width: 15px;
        margin-top: 3px;
      }
    }
    &:focus {
      outline: none;
      &::-webkit-slider-runnable-track,
      &::-ms-fill-lower,
      &::-ms-fill-upper {
        background: #fff;
      }
    }
  }

  &__track-progress {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 20;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 100%;
    border-radius: 5px;
    background: transparent;
    border: none;
    @media all and (-ms-high-contrast: none) {
      color: #fff;
      border: none;
      background-color: transparent;
    }
    @supports (-ms-ime-align: auto) {
      color: #fff;
      border: none;
    }
    &[value] {
      &::-webkit-progress-bar {
        background: none;
        border-radius: 5px;
      }
      &::-webkit-progress-value {
        background-color: #fff;
        border-radius: 5px;
      }
    }
    &::-moz-progress-bar {
      background: none;
      border-radius: 5px;
      background-color: #fff;
      height: 100%;
    }
    &::-webkit-progress-bar {
      background: #fff;
      border-radius: 5px;
    }
    &::-webkit-progress-value {
      background-color: none;
      border-radius: 5px;
    }
  }

  &__buffered-progress {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 10;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 100%;
    border-radius: 5px;
    border: none;
    background-color: rgba($color: $color-main, $alpha: 0.5);
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    background-image: -webkit-gradient(
      linear,
      50% 0%,
      50% 100%,
      color-stop(0%, #fff),
      color-stop(100%, #fff)
    );
    background-size: 00% 100%;
    background-repeat: no-repeat;
    &[value] {
      &::-webkit-progress-bar {
        background-color: transparent;
        border-radius: 5px;
      }
      &::-webkit-progress-value {
        background-color: rgba($color: #000, $alpha: 0.2);
        border-radius: 5px;
        transition: width 1s ease;
      }
    }
    &::-moz-progress-bar {
      background-color: rgba($color: #000, $alpha: 0.2);
      border-radius: 5px;
      height: 100%;
    }
    @-moz-document url-prefix() {
      top: 0;
      border: none;
    }
    @media all and (-ms-high-contrast: none) {
      color: #78909c;
      border: none;
    }
    @supports (-ms-ime-align: auto) {
      color: #78909c;
      border: none;
    }
  }
  &__voluem-wrapper {
    display: flex;
    justify-content: stretch;
    align-items: flex-end;
    position: relative;
    padding-right: 40px;
    @include mq($until: xs) {
      padding-right: 30px;
    }
  }
  &__volume-mute {
    background-color: transparent;
    position: absolute;
    bottom: -8px;
    right: 0;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq($until: xs) {
      bottom: -7px;
    }
    &:hover img {
      transform: scale(1.1);
    }
    img {
      transition: transform 0.2s;
    }
  }
  &__volume-icon {
    cursor: pointer;
    @include mq($until: xs) {
      width: 25px;
    }
  }
  &__volume {
    overflow: hidden;
    height: 8px;
    width: 100%;
    outline: none;
    padding: 0;
    background-color: $color-main;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    background-image: -webkit-gradient(
      linear,
      50% 0%,
      50% 100%,
      color-stop(0%, #fff),
      color-stop(100%, #fff)
    );
    background-size: 0% 100%;
    background-repeat: no-repeat;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    -webkit-appearance: none;
    // @include mq($until: sm) {
    //   width: 80px;
    // }
    // @include mq($until: xs) {
    //   width: 70px;
    // }

    // chrome
    &::-webkit-slider-thumb {
      box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
      border: 1px solid #fff;
      height: 15px;
      width: 15px;
      border-radius: 16px;
      background: #fff;
      cursor: pointer;
      -webkit-appearance: none;
    }

    &::-webkit-slider-runnable-track {
      box-shadow: none;
      border: none;
      background: transparent;
      -webkit-appearance: none;
    }

    // mozilla
    &::-moz-range-thumb {
      opacity: 0;
      outline: none;
      cursor: pointer;
    }

    &::-moz-range-progress {
      background-color: #fff;
      height: 100%;
      border: none;
    }
    &::-moz-range-track {
      background-color: $color-main;
      border-radius: 30px;
      border: none;
      height: 100%;
      box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    }

    // //IE
    &::-ms-fill-lower {
      background-color: #fff;
      height: 100%;
      border: none;
    }

    &::-ms-fill-upper {
      background-color: $color-main;
      border-radius: 30px;
      border: none;
      height: 100%;
      box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
    }
  }

  &__controls-wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__control-btn {
    flex-shrink: 0;
    background-color: #fff;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.03);
    border: none;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    display: flex;
    transition: 0.3s;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &--prev {
      padding-left: 0px;
    }
    &--next {
      padding-left: 3px;
    }
    &--play-pause {
      margin: 0 10px;
      svg {
        display: none;
        &#icon-play {
          margin-left: 3px;
        }
        &#icon-pause {
          margin-left: 1px;
        }
      }
    }
    &:hover {
      transform: scale(1.08);
      box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.12);
    }
    svg {
      width: 15px;
      fill: $color-main;
      &:hover {
        fill: $color-main;
      }
    }
  }
}

.amplitude-paused {
  svg#icon-play {
    display: block;
  }
  svg#icon-pause {
    display: none;
  }
}
.amplitude-playing {
  svg#icon-play {
    display: none;
  }
  svg#icon-pause {
    display: block;
  }
}
