.podcast-series {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  // grid-template-columns: repeat(4, 1fr);
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  grid-gap: 30px;
  @include mq($until: xl) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include mq($until: md) {
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    // width: 240px;
    width: 100%;
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    transition: transform 0.2s;
    box-shadow: 1px 1px 17px -2px rgba(0, 0, 0, 0.1);
    &:hover {
      transform: scale(1.03);
    }
  }
  &__thumb {
    // width: 240px;
    // height: 240px;
    width: 100%;
    height: 16.5vw;
    max-height: 362px;
    background-size: auto;
    background-size: cover;
    background-position: center;
    @media screen and (max-width: 1730px) {
      height: 14.5vw;
    }
    @include mq($until: xl) {
      height: 17.5vw;
    }
    @media screen and (max-width: 1300px) {
      height: 16vw;
    }
    @include mq($until: lg) {
      max-height: none;
      height: 29.2vw;
    }
    @include mq($until: md) {
      height: 40.5vw;
    }
  }
  &__content-wrapper {
    text-align: center;
    width: 100%;
    background-color: #fff;
    font-weight: $fw-bold;
    padding: 15px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__title {
    font-size: rem(16px);
  }
  &__not-found {
    display: block;
    text-align: center;
    color: $border-color;
    font-size: rem(22px);
    &:before {
      display: none;
    }
  }
}
