.home-adds {
  position: relative;
  @include global-gradient($color-main, $color-extra);
  &__inner-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 400px;
    justify-content: center;
    align-items: center;
    width: 100%;
    @include mq($until: xxl) {
      padding-right: 0;
      max-width: none;
    }
    @include mq($until: sm) {
      grid-template-columns: 1fr;
      grid-template-rows: 250px 250px;
      padding-left: 0;
    }
  }
  &__text-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: calc(100% - 140px);
      width: 280px;
      height: 100%;
      @include global-gradient($color-main, $color-extra);
      clip-path: polygon(0 0, 50% 0, 100% 100%, 0% 100%);
      z-index: 2;
      @include mq($until: sm) {
        display: none;
      }
    }
  }
  &__image {
    background-repeat: no-repeat;
    @include global-gradient($color-main, $color-extra);
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
    position: relative;
    @include mq($until: sm) {
      background-position: top center;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: calc(100% - 140px);
      width: 280px;
      height: 100%;
      @include global-gradient($color-main, $color-extra);
      clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 99%);
      z-index: 2;
      @include mq($until: xxl) {
        display: none;
      }
      @include mq($until: sm) {
        display: none;
      }
    }
  }
  &__heading-top {
    font-size: rem(50px);
    margin: 0 60px 10px 0;
    font-weight: $fw-bold;
    position: relative;
    z-index: 3;
    @include mq($until: xl) {
      font-size: rem(40px);
    }
    @include mq($until: lg) {
      font-size: rem(35px);
    }
    @include mq($until: md) {
      margin: 0 30px 10px 0;
    }
  }
  &__heading-bottom {
    font-size: rem(50px);
    margin: 0 0 0 60px;
    font-weight: $fw-bold;
    position: relative;
    z-index: 3;
    @include mq($until: xl) {
      font-size: rem(40px);
    }
    @include mq($until: lg) {
      font-size: rem(35px);
    }
    @include mq($until: md) {
      margin: 0 0 0 30px;
    }
  }
  &__link {
    font-weight: $fw-bold;
    position: relative;
    color: $color-font-light;
    font-size: rem(30px);
    margin-top: 20px;
    z-index: 3;
    @include mq($until: xl) {
      font-size: rem(25px);
    }
    @include mq($until: lg) {
      font-size: rem(20px);
    }
    // &:before{
    //     content: "";
    //     position: absolute;
    //     top: calc(100% + 8px);
    //     left: 50%;
    //     transform: translateX(-50%);
    //     width: 100%;
    //     height: 1px;
    //     background-color: $color-font-light;
    //     transition:  background-color .3s;
    // }
    // &:hover:before{
    //     background-color: #000;
    // }
  }
}
