.aside {
  display: grid;
  grid-gap: 40px;
  grid-template-columns: 1fr;
  @include mq($until: lg) {
    grid-template-columns: repeat(3, 1fr);
  }
  @include mq($until: md) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include mq($until: sm) {
    grid-template-columns: 1fr;
  }
  &__item {
    position: relative;
    // width: 338px;
    // height: 181px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mq($until: xl) {
      // width: 300px;
      // height: 153px;
    }
    @include mq($until: lg) {
      margin: 0;
      // width: 100%;
      // height: 173px;
    }
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__link {
    &:hover {
      .aside__image {
        transform: scale(1.05);
      }
    }
  }
  &__image {
    border: 1px solid transparent;
    display: block;
    position: relative;
    border-radius: 5px;
    transition: transform 0.2s;
  }
}
