.news-card {
  display: grid;
  grid-template-columns: 400px 1fr;
  align-items: stretch;
  justify-content: center;
  background-color: $color-gray;
  border-radius: 5px;
  -webkit-box-shadow: -2px 0px 20px 1px rgba(0, 0, 0, 0.14);
  -moz-box-shadow: -2px 0px 20px 1px rgba(0, 0, 0, 0.14);
  box-shadow: -2px 0px 20px 1px rgba(0, 0, 0, 0.14);
  overflow: hidden;
  min-height: 200px;
  transition: background-color 0.2s;
  @include mq($until: xl) {
    grid-template-columns: 300px 1fr;
  }
  @include mq($until: lg) {
    grid-template-columns: 300px 1fr;
  }
  @include mq($until: md) {
    grid-template-columns: 1fr;
    grid-template-rows: 26vh auto;
    .news-card__image {
      width: 100%;
      height: auto;
    }
  }
  &:nth-child(even) {
    grid-template-columns: 1fr 400px;
    @include mq($until: xl) {
      grid-template-columns: 1fr 300px;
    }
    @include mq($until: lg) {
      grid-template-columns: 1fr 300px;
    }
    @include mq($until: md) {
      grid-template-columns: 1fr;
    }
    .news-card__image {
      order: 2;
    }
  }
  &--promo {
    grid-template-columns: 4fr 3fr;
    grid-template-rows: 1fr;
    min-height: 450px;
    @include mq($until: xxl) {
      min-height: 400px;
    }
    @include mq($until: xl) {
      min-height: 350px;
    }
    @include mq($until: lg) {
      min-height: 300px;
    }
    @include mq($until: sm) {
      grid-template-columns: 1fr;
      grid-template-rows: 26vh auto;
      min-height: 200px;
    }
    .news-card__content-wrapper {
      padding: 50px;

      @include mq($until: md) {
        padding: 40px;
      }
      @include mq($until: sm) {
        padding: 20px;
      }
    }
    .news-card__header {
      font-size: rem(36px);
      @include mq($until: xxl) {
        font-size: rem(32px);
      }
      @include mq($until: xl) {
        font-size: rem(28px);
      }
      @include mq($until: lg) {
        font-size: rem(26px);
      }
      @include mq($until: md) {
        font-size: rem(24px);
      }
      @include mq($until: sm) {
        font-size: rem(20px);
      }
    }
    .news-card__image {
      @include mq($until: md) {
        height: 100%;
      }
    }
  }

  &:hover {
    background-color: #000;
    .news-card__header {
      color: #fff;
    }
    .news-card__excerpt {
      color: #fff;
    }
    .news-card__published-at {
      color: #fff;
    }
  }
  &__image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @include mq($until: md) {
      height: 300px;
      width: 100%;
      order: 1;
    }
  }
  &__content-wrapper {
    padding: 40px;
    display: flex;
    flex-direction: column;
    @include mq($until: md) {
      order: 2;
      padding: 20px;
    }
  }
  &__header {
    font-size: rem(20px);
    margin-bottom: 25px;
    transition: color 0.2s;
  }
  &__excerpt {
    transition: color 0.2s;
    margin-bottom: 25px;
  }
  &__more {
    margin-top: auto;
    padding-top: 15px;
    font-weight: $fw-bold;
    color: $color-main;
  }
  &__published-at {
    font-size: rem(13px);
    margin-top: auto;
    margin-left: auto;
    transition: color 0.2s;
  }
}
