

.weather-widget-wrapper{
    opacity: 0;
    height: 98px;
    transition: opacity 1s;
    &.show{
        opacity: 1;
    }
}

.weather-widget{
    @include mq($until: md){
        display: none !important;
    }
    h1{
        font-size: rem(26px) !important;
    }
}

.locationName{
    font-size: rem(30px) !important;
}