.results__searched-item{
    margin-left: 10px;
    color: $color-main;
}

.ss-result{
    display: flex;
    border-bottom: 1px solid $color-gray;
    padding-bottom: 30px;
    margin-bottom: 50px;
    &__aside{
        flex-basis: 140px;
        min-width: 140px;
        border-radius: 5px;
        padding-right: 20px;
        @include mq($until: xs) {
            display: none;
        }
    }
    &__content{
        flex-basis: max-content;
        padding-left: 20px;
    }
    hr{
        display: none;
    }
}