@keyframes ticker {
    0% {
      left: 100%;
      transform: translateX(0);
    }
  
    100% {
      left: 0;
      transform: translateX(-100%);
    }
}

.top-baner{
    background-image: url('../../images/top-foto.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    height: calc(100vh - 152px);
    position: relative;
    width: 100%;
    @include mq($until: md){
        height: calc(100vh - 95px);
    }
    &__player-container{
        position: relative;
        width: 100%;
        top: 50%;
        // transform: translate(-50%, 20%);
        @include mq($until: xl){
            padding: 0;
            left: -20px;
            // transform: translateY(20%);
        }

    }
    &--full-height{
        margin-top: 152px;
        @include mq($until: md){
            margin-top: 95px;
        }
    }
    &__box-wrapper{
        width: 475px;
        height: 200px;
        margin: 0;
        background-image: url('../../images/play-box.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: -3px center;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        overflow: hidden;
        flex-direction: column;
        @include mq($until: xs){
            width: 80%;
            background-size: auto 80%;
            background-position: top right;
        }
        @media (max-width: 400px) {
            width: 90%;
            background-size: auto 90%;
            height: 170px;
        }
    }
    &__heading{
        font-size: rem(56px);
        color: $color-font-light;
        font-weight: $fw-bold;
        text-shadow: -1px 3px 4px rgba(7, 5, 5, 0.39);
        margin: 0 0 15px 0;
        text-align: right;
        width: 75%;
        padding-top: 30px;
        @include mq($until: xs){
            padding-top: 4%;
            margin: 0 20px 10px 0;
            width: 79%;
        }
        @media (max-width: 400px) {
            width: 83%;
            padding-top: 6%;
        }
    }
    &__button-wrapper{
        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: absolute;
        bottom: 22%;
        left: 0;
        min-height: 55px;
        width: 75%;
        padding-right: 115px;
        @include mq($until: xs){
            bottom: 30%;
            padding-right: 105px;
        }
        @media (max-width: 400px) {
            bottom: 17%;
            padding-right: 90px;
        }
    }

    &__ticker-wrapper{
        overflow: hidden;
        position: absolute;
        width: calc(100% - 40px);
        height: 100%;
        left: 0;
    }

    &__ticker{
        color: $color-font-light;
        font-size: rem(30px);
        font-weight: $fw-bold;
        text-shadow: -1px 3px 4px rgba(0, 0, 0, 0.39);
        position: absolute;
        // left: auto;
        // right: 0;
        transform: translateX(0);
        // padding-right: 20%;
        white-space: nowrap;
        right: auto;
        left: 100%;
        padding-right: 0;
        -webkit-animation-iteration-count: infinite; 
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-name: ticker;
        animation-name: ticker;
        -webkit-animation-duration: 10s;
        animation-duration: 10s;
        // &.play{

        // }

    }
    &__play-button{
        border: none;
        background-color: transparent;
        position: absolute;
        top: 5px;
        right: 0;
        color: $color-font-light;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        z-index: 1;
        @include mq($until: xs){
            top: 0;
        }
        svg{
            width: 100px;
            height: 91px;
            .inside-shape{
                transition: fill .3s;
            }
            &:hover{
                .inside-shape{
                    fill: $color-font-dark;
                }
            }
            @include mq($until: xs){
                width: 90px;
                height: 81.91px;
            }
            @media (max-width: 400px) {
                width: 80px;
                height: 72.8px;
            }
        }
    }

    &__stream-wrapper{
        display: none;
    }

    &__background-play-wrapper{
        position: relative;
        width: 100%;
        // height: 100%;
        top: 10px;
        @include mq($until: sm){
            top: 5px;
        }
        @include mq($until: xs){
            top: 0;
        }
        @media (max-width: 400px) {
            top: -3px;
        }
    }

    &__background-play{
        border-radius: 10px;
        background-color: transparent;
        color: $color-font-light;
        padding: 0;
        position: relative;
        // top: 50%;
        left: 100%;
        // right: 0;
        // transform: translate(-100%, -50%);
        transform: translateX(-100%);
        border: none;
        font-size: rem(16px);
        font-style: italic;
        outline: none;
        transition: color .3s;
        text-shadow: -1px 3px 4px rgba(7, 5, 5, 0.39);
        @include mq($until: xs){
            // top: 79%;
            // left: 12vw;
            // transform: translateY(-50%);
        }
        @media (max-width: 400px) {
            // left: 12%;
        }
        &:after{
            height: 2px;
            width: 100%;
            content: "";
            background-color: #fff;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            transition: background-color .3s;
        }
        &:hover{
            color: $color-font-dark;
            &:after{
                background-color: #000;
            }
        }
    }
}