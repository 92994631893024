.page-title {
  margin-bottom: 30px;
  font-size: rem($header-size / 1.3);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @include mq($until: md) {
    // margin-bottom: 0;
    // margin-bottom: 40px;
  }
  @include mq($until: xs) {
    // margin-bottom: 0;
    font-size: rem(25px);
  }
  &--secondary {
    color: $color-main;
    font-size: inherit;
    font-style: italic;
  }
}
