.news {
  display: grid;
  grid-template-columns: 1fr 600px;
  grid-column-gap: 50px;
  grid-row-gap: 40px;
  @include mq($until: xl) {
    grid-template-columns: 1fr 500px;
  }
  @include mq($until: lg) {
    grid-template-columns: 1fr 400px;
  }
  @include mq($until: md) {
    grid-template-columns: 100%;
  }

  &__left-column {
    @include mq($until: md) {
      order: 2;
    }
    .news__title {
      @include mq($until: md) {
        display: none;
      }
    }
    .news__details {
      display: flex;
      @include mq($until: md) {
        display: none;
      }
    }
  }
  &__right-column {
    @include mq($until: md) {
      order: 1;
    }
    div,
    span,
    p {
      outline: none;
    }
    .news__title {
      display: none;
      @include mq($until: md) {
        display: block;
      }
    }
    .news__details {
      display: none;
      @include mq($until: md) {
        display: flex;
      }
    }
  }
  &__details {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  &__author {
    margin-left: 30px;
    font-style: italic;
    margin-bottom: 10px;
  }
  &__date {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    img {
      margin-right: 10px;
    }
    p {
      font-style: italic;
      font-weight: $fw-bold;
    }
  }
  &__specification {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 30px;
    p {
      font-weight: $fw-bold;
      margin-right: 5px;
      color: $color-main;
    }
  }

  &__specifications {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    a,
    span {
      color: $color-font-dark;
      &::after {
        content: "|";
        margin-left: 10px;
        margin-right: 10px;
      }
      &:last-of-type::after {
        display: none;
      }
    }
  }

  &__content {
    margin-bottom: 30px;
  }

  &__main-slider {
    // margin-bottom: 30px;
  }
  &__main-slider-image {
    margin-bottom: 40px;
  }
  &__main-slider-link {
    outline: none;
    padding: 10px;
    position: relative;
  }

  &__image-author {
    position: absolute;
    bottom: 18px;
    left: 20px;
    font-size: rem(14px);
    color: #545454;
  }

  &__main-slider-image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 400px;
    width: 100%;
    border-radius: 5px;
    @include mq($until: md) {
      height: 300px;
    }
  }

  &__nav-slider {
    .slick-current.slick-active .news__nav-slider-image {
      border: 4px solid $color-main;
    }
  }

  &__nav-slider-image-wrapper {
    width: calc(600px / 4);
    height: calc(600px / 4);
    padding: 10px;
    outline: none;
    @include mq($until: xl) {
      width: calc(500px / 3);
      height: calc(500px / 3);
    }
    @include mq($until: lg) {
      width: calc(400px / 3);
      height: calc(400px / 3);
    }
  }
  &__nav-slider-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    width: 100%;
    border: 4px solid transparent;
    transition: border-color 0.2s;
    border-radius: 5px;
  }
}
