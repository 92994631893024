.content {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 40px;
  margin-top: 30px;
  min-height: 100vh;
  width: 100%;
  margin-bottom: 70px;
  align-items: flex-start;
  @include mq($until: lg) {
    grid-template-columns: 1fr;
    grid-gap: 70px;
  }
  @include mq($until: md) {
    grid-gap: 40px;
  }

  &--fixed {
    margin-top: 360px;
    @include mq($until: md) {
      margin-top: 220px;
    }
  }
  &--news {
    display: block;
    grid-template-columns: none;
    grid-gap: 0;
  }
  &--home {
    .news-card__published-at {
      display: none;
    }
  }
}
