@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.history {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__pagination {
    display: block;
    margin: 0;
    padding: 0;
    width: 600px;
    @include mq($until: xl) {
      width: 400px;
    }
    @include mq($until: lg) {
      width: 600px;
    }
    @include mq($until: md) {
      width: 400px;
    }
    @include mq($until: sm) {
      width: 350px;
    }
    @include mq($until: xs) {
      width: 200px;
    }
  }
  &__hour {
    margin: 0;
    padding: 20px 20px;
    background-color: #f7f7f7;
    color: $color-font-dark;
    font-weight: $fw-bold;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.2s;
    &:hover {
      background-color: $color-main;
    }
    &--active {
      background-color: $color-main;
    }
    &:before {
      display: none;
    }
  }

  &__pagination-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    opacity: 0;
    transition: opacity 1s;
    &--loaded {
      opacity: 1;
    }
  }

  &__pagination-arrow {
    padding: 20px 30px;
    background-color: #f7f7f7;
    color: $color-font-dark;
    font-weight: $fw-bold;
    cursor: pointer;
    transition: background-color 0.2s;
    border: none;
    text-align: center;
    @include mq($until: sm) {
      padding: 20px;
    }
    &--left {
      border-radius: 0 5px 5px 0;
    }
    &--right {
      border-radius: 5px 0 0 5px;
    }
    &:hover {
      background-color: $color-main;
    }
  }

  &__content-wrapper {
    width: 100%;
  }

  &__items {
    display: none;
    animation: fadeEffect 1s; /* Fading effect takes 1 second */
    overflow: hidden;
    margin: 0;
    margin-top: 40px;
    &--active {
      display: block;
    }
  }
  &__item {
    margin: 0;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    border-radius: 5px;
    background-color: #f7f7f7;
    &:nth-child(even) {
      background-color: #ffffff;
    }
    &::before {
      display: none;
    }
  }
  &__play-time {
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: $fw-bold;
    color: $color-main;
    width: 120px;
  }

  &__track-content {
    padding: 30px;
    display: grid;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 5px;
  }
  &__title {
  }
  &__artist {
    font-size: rem(22px);
    font-weight: $fw-semi-bold;
  }
  @include mq($until: xs) {
    &__play-time {
      padding: 20px;
      width: 70px;
      min-width: 70px;
    }

    &__track-content {
      padding: 30px 20px;
    }

    &__artist {
      font-size: rem(18px);
    }

  } 
}
