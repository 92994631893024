// PX to REM - Usage: font-size: rem(37px);
@function rem($px, $base: $base-font-size) {
    @return ($px / $base) * 1rem;
}

@mixin global-gradient($first-color, $second-color) {
    background: $first-color;
    background: linear-gradient(0deg, rgba($color: $first-color, $alpha: 1.0) 0%, rgba($color: $second-color, $alpha: 1) 100%);
}

