.news-list {
  display: grid;
  grid-gap: 40px;

  &__header-wrapper {
    display: grid;
    justify-content: flex-start;
    grid-template-columns: repeat(2, auto);
    justify-items: flex-start;
    align-items: flex-end;
    grid-gap: 20px;
    margin-bottom: 30px;
    flex-wrap: wrap;
    // @include mq($until: md) {
    //   margin-bottom: 40px;
    // }
    @include mq($until: xs) {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }
  }
  &__header {
    margin-bottom: 30px;
    font-size: rem($header-size / 1.3);
    line-height: rem($header-size / 1.3);
    &--grid {
      margin-bottom: 0;
    }
  }
  &__link {
    color: $color-main;
    position: relative;
    font-weight: $fw-bold;
    line-height: rem($header-size / 1.85);
    @include mq($until: xs) {
      line-height: rem($header-size);
    }
    // &:before {
    //   content: "";
    //   position: absolute;
    //   bottom: 0;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   height: 1px;
    //   width: 100%;
    //   background-color: $color-main;
    //   transition: background-color 0.2s;
    // }
    // &:hover:before {
    //   background-color: $color-font-dark;
    // }
  }
}
