.searchbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 50px;
  @include mq($until: xl) {
    padding-right: 10px;
  }
  @include mq($until: md) {
    padding-right: 0;
  }
  &__wrapper {
    border: 1px solid #cecece;
    border-radius: 20px;
    padding: 5px;
    position: relative;
    width: 100%;
    @include mq($until: md) {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 39px;
      border-radius: 30px;
      border: none;
      background-color: #fff;
    }
  }
  &__input {
    border: none;
    outline: none;
    padding: 0 10px;
    width: calc(100% - 27px);
    @include mq($until: lg) {
      // width: 110px;
    }
    @include mq($until: md) {
      background-color: transparent;
      padding: 6px 20px;
    }
  }
  &__button {
    position: absolute;
    border: none;
    border-radius: 100%;
    background-color: $color-main;
    color: $color-font-light;
    background-color: $color-extra;
    transition: background-color 0.2s;
    outline: none;
    height: 27px;
    width: 29px;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    @include mq($until: md) {
      height: 37px;
      width: 39px;
    }
    &:hover {
      background-color: $color-main;
    }
  }
}
