.day-fullname {
  color: $color-main;
  font-size: inherit;
  margin-left: 5px;
  // padding-left: 30px;
  font-style: italic;
  // position: relative;
  // &:before {
  //   content: "-";
  //   font-style: italic;
  //   color: #000;
  //   font-size: inherit;
  //   position: absolute;
  //   top: 50%;
  //   left: 0;
  //   transform: translateY(-50%);
  // }
}

@keyframes fadeEffect {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.schedule {
  display: grid;
  grid-gap: 70px;
  @include mq($until: md) {
    margin-top: 40px;
    grid-gap: 40px;
  }
  // @include mq($until: xs) {
  //   margin-top: 60px;
  // }
  &__days {
    margin: 0;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 20px;
    @include mq($until: xs) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: flex-start;
    }
  }
  &__day {
    padding: 0;
    margin: 0;
    &:before {
      display: none;
    }
    @include mq($until: xs) {
      min-width: 100px;
    }
  }
  &__day-button {
    border: none;
    padding: 10px;
    background-color: #000;
    border-radius: 5px;
    color: $color-font-light;
    width: 100%;
    height: 100%;
    font-weight: $fw-bold;
    font-style: italic;
    transition: background-color 0.2s;
    -webkit-box-shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.52);
    -moz-box-shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.52);
    box-shadow: 0px 2px 9px -2px rgba(0, 0, 0, 0.52);
    outline: none;
    &:hover,
    &.active {
      background-color: $color-main;
    }
  }
  &__constat {
    display: grid;
    grid-gap: 20px;
  }

  &__constat-heading {
    font-size: rem(25px);
    margin: 0;
  }

  &__constat-items {
    margin: 0;
    display: none;
    overflow: hidden;
    animation: fadeEffect 1s; /* Fading effect takes 1 second */
  }

  &__constat-item {
    margin: 0;
    padding: 15px;
    display: grid;
    grid-gap: 10px;
    border-radius: 5px;
    &:before {
      display: none;
    }
    &:nth-child(odd) {
      background-color: $color-gray;
    }
  }

  &__item-heading {
    font-size: rem(20px);
    margin: 0;
  }

  &__constat-hours {
    display: flex;
    flex-wrap: wrap;
  }
  &__constat-hour {
    margin: 10px;
    padding: 0;
    &:before {
      display: none;
    }
  }

  &__not-found {
    padding: 25px 15px;
    display: block;
    text-align: center;
  }

  &__daily {
    border: 1px solid $color-main;
    overflow: hidden;
    border-radius: 5px;
    display: none;
    animation: fadeEffect 1s; /* Fading effect takes 1 second */
  }

  &__daily-heading {
    font-size: rem(25px);
    background-color: $color-main;
    color: $color-font-light;
    padding: 25px 15px;
    font-weight: $fw-bold;
    font-style: italic;
    margin: 0;
  }

  &__daily-items {
    margin: 0;
    display: grid;
  }

  &__daily-item {
    margin: 0;
    padding: 20px 15px;
    display: flex;
    flex-wrap: wrap;
    &:before {
      display: none;
    }
    &:nth-child(even) {
      background-color: $color-gray;
    }
  }

  &__daily-hour {
    font-weight: $fw-bold;
    margin-right: 15px;
    width: 50px;
    @include mq($until: sm) {
      margin-right: 10px;
    }
  }

  &__daily-title {
    font-style: italic;
  }
  &__daily-subitems {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  &__daily-subitem {
    margin: 0;
    padding: 20px 0 0 100px;
    @include mq($until: sm) {
      padding: 20px 0 0 80px;
    }
    @include mq($until: xs) {
      padding: 20px 0 0 0;
    }
    &:before {
      display: none;
    }
  }
  &__daily-subitem-hour {
    font-size: rem(14px);
    font-weight: $fw-bold;
    white-space: nowrap;
    text-align: right;
    margin-right: 1px;
    width: 17px;
  }

  &__daily-separator {
    font-size: rem(14px);
    font-weight: $fw-bold;
    white-space: nowrap;
    margin: 0;
  }

  &__daily-subitem-minute {
    font-size: rem(14px);
    font-weight: $fw-bold;
    white-space: nowrap;
    text-align: left;
    width: 17px;
  }

  &__daily-subitem-text {
    font-size: rem(14px);
    margin-left: 20px;
  }
}
