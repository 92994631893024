.svg-icon {
  transition: fill 0.3s ease-in-out;
  @include mq($until: xs) {
    max-width: 25px;
  }
}
.contact-data {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 50px;
  grid-gap: 30px;

  @include mq($until: md) {
    grid-template-columns: 1fr;
  }

  &__left-col {
    display: grid;

    @include mq($until: md) {
      grid-gap: 30px;
    }
  }

  &__section {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    p {
      margin-bottom: 0;
    }
    &--name {
      display: block;
      p {
        font-size: rem(20px);
        font-weight: $fw-semi-bold;
      }
    }
    &--social .svg-icon {
      max-width: unset;
      margin-right: 5px;
      @include mq($until: xs) {
        max-width: 35px;
      }
    }
    &--social a:hover .svg-icon {
      fill: $color-main;
    }
  }

  &__icon {
    margin-right: 15px;
  }
  &__content {
    &--phones,
    &--emails {
      display: grid;
      grid-gap: 20px;
    }
  }
  &__item {
    display: grid;
    grid-gap: 2px;
    justify-content: flex-start;
    align-items: center;
  }

  &__label {
    margin-right: 15px;
  }
  &__email {
    font-weight: $fw-semi-bold;
  }
  &__phone {
    font-weight: $fw-semi-bold;
  }
}
